import React, { createContext, useContext } from "react";
import { useReducer } from "react";
import payoutRequestReducer from "./reducer";

const initialState = {
  selectedRq: [],
};
export const PayoutContext = createContext(null);

const PayoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(payoutRequestReducer, initialState);
  return (
    <PayoutContext.Provider value={{ state, dispatch }}>
      {children}
    </PayoutContext.Provider>
  );
};
export default PayoutProvider;

export const usePayoutContext = () => useContext(PayoutContext);
