import Ternary from "src/components/ternary";
import useSettings from "src/hooks/useSettings";
import Horizontal from "./components/horizontal";
import Vertical from "./components/vertical";
import useAuth from "src/hooks/useAuth";

function removeMonoLineFromGenealogy(menu_item, user_profile) {
  for (let item of menu_item) {
    if (item.path === "/user/genealogy") {
      if (user_profile?.is_eighth_invested === 0) {
        item.children = item.children.filter(child => child.path !== "/user/genealogy/board8");
      }

      if (user_profile?.is_seventh_invested === 0) {
        item.children = item.children.filter(child => child.path !== "/user/genealogy/board7");
      }

      if (user_profile?.is_sixth_invested === 0) {
        item.children = item.children.filter(child => child.path !== "/user/genealogy/board6");
      }

      if (user_profile?.is_fifth_invested === 0) {
        item.children = item.children.filter(child => child.path !== "/user/genealogy/board5");
      }

      if (user_profile?.is_fourth_invested === 0) {
        item.children = item.children.filter(child => child.path !== "/user/genealogy/board4");
      }

      if (user_profile?.is_third_invested === 0) {
        item.children = item.children.filter(child => child.path !== "/user/genealogy/board3");
      }

      if (user_profile?.is_second_invested === 0) {
        item.children = item.children.filter(child => child.path !== "/user/genealogy/board2");
      }

      if (user_profile?.is_first_invested === 0) {
        item.children = item.children.filter(child => child.path !== "/user/genealogy/board1");
      }
    }
  }
}

const Layout = () => {
  const config = JSON.parse(localStorage.getItem("menu") || "[]");

  const { user, isAdmin, isSubAdmin } = useAuth();

  const { themeLayout } = useSettings();
  const verticalLayout = themeLayout === "vertical";

  if (!isAdmin && !isSubAdmin)
    removeMonoLineFromGenealogy(config?.[0]?.items, user?.user_profile);

  return (
    <Ternary
      when={verticalLayout}
      then={<Vertical navConfig={config} />}
      otherwise={<Horizontal navConfig={config} />}
    />
  );
};
export default Layout;
