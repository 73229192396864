import {
  ADD,
  REQUEST_RESET,
  REMOVE,
  SET_PAYOUT_REQUEST,
} from "./type";

const payoutRequestReducer = (state, { type, payload }) => {
  switch (type) {
    case ADD:
      return {
        ...state,
        selectedRq: [
          ...state.selectedRq,
          {
            id: payload.id,
            address: payload.address,
            wei_amount: payload.wei_amount,
            amount: payload.amount,
          },
        ],
      };
    case REMOVE:
      const prev = [...state.selectedRq];
      prev?.splice(payload?.index, 1);
      return {
        ...state,
        selectedRq: prev,
      };
    case REQUEST_RESET:
      return { ...state, selectedRq: [] };
    case SET_PAYOUT_REQUEST:
      return { ...state, selectedRq: payload };
    // case CHANGE_TAB:
    //   return { ...state, product_tab: payload };
    default:
      return state;
  }
};

export default payoutRequestReducer;
